<template>
  <div class="liaison">
    <p class="liaison__text">
      Your dedicated member liaison is here to help. Get in touch with questions about ticket booking, our events, if
      you'd like help with a destination that's not yet listed here. You name it, as long as it's art related, we'll do
      our best to help.
    </p>
    <div v-if="liaison.name" class="user-box">
      <div class="user-box__col user-data">
        <div
          v-if="liaison.photo_url"
          class="user-data__photo bg"
          :style="`background-image:url(${liaison.photo_url})`"
        />
        <div class="d-flex flex-column">
          <div class="user-data__name">
            <span>{{ liaisonFirstName }}</span>
            <span>{{ liaisonLastName }}</span>
          </div>
          <div class="user-data__info">
            <p>{{ liaison.email }}</p>
            <p v-if="liaison.phone">{{ liaison.phone }}</p>
          </div>
        </div>
      </div>

      <div class="user-box__col user-buttons">
        <a class="btn border-btn" :href="`mailto:${liaison.email}?subject=Make a request`">EMAIL</a>

        <template v-if="liaison.phone">
          <a v-if="!desktopMode" class="btn border-btn" :href="`tel:${liaison.phone}`">Call</a>
          <a v-else class="btn border-btn" :href="`skype:${liaison.phone}?call`">Call</a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MemberLiaisonBox',
  props: {
    liaison: {
      type: Object,
      default: () => ({
        email: '',
        name: '',
        phone: '',
        photo_url: '',
      }),
    },
  },
  data() {
    return {
      desktopMode: true,
    };
  },
  computed: {
    liaisonFirstName() {
      return this.liaison.name.split(' ')[0];
    },
    liaisonLastName() {
      return this.liaison.name.substring(this.liaisonFirstName.length + 1);
    },
  },
  created() {
    if (window.innerWidth < 978) {
      this.desktopMode = false;
    }
  },
};
</script>
